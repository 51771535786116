<template>
  <view-container breadcrumbs scroll-fab>
  <!-- <full-view :title="$t('app.route.favorites')" fab="mdi-arrow-up" @fab:click="goTop()"> -->
    <div class="d-flex">
      <v-btn
        @click="() => $$router.replace({ name: 'store' })"
        depressed
        tile
        height="40"><v-icon> mdi-arrow-left </v-icon>
      </v-btn>

      <v-row v-if="loading" no-gutters>
        <div class="d-flex">
          <v-skeleton-loader
            v-for="i in [1,2, 3]"
            :key="i"
            type="list-item"
            tile
            max-height="40"
            width="100"
          ></v-skeleton-loader>
        </div>
      </v-row>

      <bookmark-tabs
        v-else
        v-model="tab"
        :items="getSections"
        id-prefix="sec-"
        :container="$refs.viewFrame"
        style="width: 100%;"
      />
    </div>

    <div
      ref="viewFrame"
      class="overflow-y-auto px-5"
    >
      <center-content v-if="!notEmptyArray(getSections)">
        {{ $t("favorites.messages.noItems") }}
      </center-content>

      <div v-for="(sec, i) in getSections" :key="i">
        <section-header
          :title="sec.name"
          :bookmark="'sec-' + sec.id"
          icon="mdi-shape-outline"
        />
        <v-row
          class="mb-5"
          v-touch="{
            left: () => swipe(sec.id, 'left'),
            right: () => swipe(sec.id, 'right'),
          }"
        >
          <v-col
            xl="2"
            lg="3"
            md="4"
            sm="6"
            cols="6"
            v-for="item in sec.products"
            :key="item.id"
            class="pr-3"
          >
            <product-card
              :item="item"
              :favored="true"
              show-rating
              @click:card="openProduct(item)"
            />
          </v-col>
        </v-row>
      </div>
    </div>
  </view-container>
  <!-- </full-view> -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProductCard from "@/views/stores/ProductCard";
import SectionHeader from "@/common/components/SectionHeader";
import ViewContainer from "@/common/layouts/ViewContainer";
// import FullView from "@/common/layouts/FullView";
import BookmarkTabs from "@/common/components/BookmarkTabs";
import CenterContent from "@/common/components/CenterContent";
import _ from "lodash";

export default {
  name: "Favorites",
  components: { ProductCard, SectionHeader, ViewContainer, BookmarkTabs, CenterContent },
  data() {
    return {
      loading: false,
      tab: null,
    }
  },
  watch: {
    getSections: function(n) {
      if(this.notEmptyArray(n)) {
        this.tab = "sec-" + n[0].id;
      }
    }
  },
  mounted() {
    const { id } = this.$getUserInfo;
    this.loadFavoritesByParams({storeId: this.getStoreIdParam});

    this.setReactivityParams({
      resource: "FAVORITES",
      scope: "store/customer",
      key: `${this.getStoreIdParam}/${id}`,
    });
    this.$sub("reactivity/FAVORITES", (payload) => {
      this.loading = true;
      if(payload.mode !== "Delete") {
        this.reloadFavoritesByParams({ storeId: this.getStoreIdParam }).then(() => (this.loading = false));
      } else {
        this.removeStoresFromState(payload.product).then(() => (this.loading = false));
        this.loadFavoritesByParams({ storeId: this.getStoreIdParam }).then(() => (this.loading = false));
      }
      console.log("reactivity/PRODUCTS:", payload);
    });
    // when main product get deleted online
    this.$sub("reactivity/PRODUCTS", (payload) => {
      this.loading = true;
      if(payload.mode === "Delete") {
        this.loadFavoritesByParams({ storeId: this.getStoreIdParam }).then(() => (this.loading = false));
      }
      console.log("reactivity/PRODUCTS:", payload);
    });
  },
  beforeDestroy() {
    this.$unsubAll();
    this.clearReactivityParams();
  },
  computed: {
    ...mapGetters("favorites", ["getFavorites"]),

    getStoreIdParam() {
      return parseInt(this.$route.params.storeId);
    },
    getStoreParam() {
      return this.$route.params.store;
    },
    getSectionParam() {
      return this.$route.params.section;
    },
    getSectionIdParam() {
      return parseInt(this.$route.params.sectionId);
    },
    getSections() {
      const groups = _.groupBy(this.getFavorites, (o) => {
        if (o.category && o.category.section) {
          return `${o.category.section.name}`;
        } else {
          return "Other";
        }
      });

      let cats = [];
      let i = 1;
      for (let p in groups) {
        cats.push({ name: p, id: i++, products: groups[p] });
      }

      return cats;
    },
  },
  methods: {
    ...mapActions("favorites", ["loadFavoritesByParams", "reloadFavoritesByParams"]),
    ...mapActions("ui", ["setReactivityParams", "clearReactivityParams"]),

    swipe(id, dir) {
      const cats = this.getSections;
      const current = cats.find((f) => f.id === id);
      const index = cats.indexOf(current);
      if(dir === "right" && index > 0) {
        this.goTo(cats[index - 1].id); 
      } else if(dir === "left" && index < (cats.length - 1)) {
        this.goTo(cats[index + 1].id); 
      }
    },
    goTo(id) {
      this.$vuetify.goTo("#sec-" + id, { offset: -40, container: this.$refs.viewFrame });
      this.tab = "sec-" + id;
    },
    goTop() {
      if(this.notEmptyArray(this.getSections)) {
        this.tab = "sec-" + this.getSections[0].id;
        this.$vuetify.goTo("#" + this.tab, { offset: -40, container: this.$refs.viewFrame });
      }
    },
    openProduct(item) {
      const params = {
        storeId: this.getStoreIdParam,
        store: this.getStoreParam,
        section: this.getRouteName(item.category.section.name),
        sectionId: item.category.section.id,
        productId: item.id,
        back: { name: "favorites" },
      };
      this.$$router.push({ name: "product", params });
    },
  },
};
</script>
